// Should you need to overwrite any of our SCSS code or need to add any custom code, We highly recommend that you add your code into this file, so whenever theme update is available and you update the theme, You will not lose your hard work :)

// Note for dark mode:
// If you want to set different primary(i.e. light color of your primary) color in dark mode only, uncomment below code and change primary color code to set different color in dark mode. If you want your light mode and dark mode both primary color same, you can remove below code.

/*User CSS*/

[data-bs-theme="dark"] {
  --bs-primary: #67137C !important;
  --bs-primary-rgb: 103, 19, 124 !important;
  --bs-link-color: #CBAE18 !important;
  --bs-link-color-rgb: 203, 174, 24 !important;
  --bs-link-hover-color: #B79C15 !important;
  --bs-link-hover-color-rgb: 183, 156, 21;
  --bs-nav-pills-link-active-bg: #480E57 !important;

  .navbar {
    // --bs-navbar-hover-color: var(--#{$prefix}primary) !important;
    --bs-navbar-hover-color: $white !important;
    // --bs-navbar-active-color: var(--#{$prefix}primary) !important;
    --bs-navbar-active-color: #B79C15 !important; //added
  }

  .navbar-dark {
    //  --bs-navbar-hover-color: var(--#{$prefix}primary) !important;
    --bs-navbar-hover-color: $white !important;
    //  --bs-navbar-active-color: var(--#{$prefix}primary) !important;
    --bs-navbar-active-color: $white !important;
  }

  .nav {
    --bs-nav-link-hover-color: var(--#{$prefix}primary);
  }

 .dropdown-item.active { // added
      color: #B79C15;  // Replace #desiredColor with the color you want
  }

  .dropdown-menu {
    //  --bs-dropdown-link-hover-color: var(--#{$prefix}primary) !important;
    --bs-dropdown-link-hover-color: $white !important;
    //  --bs-dropdown-link-active-color: var(--#{$prefix}primary) !important;
    --bs-dropdown-link-active-color: $white !important;
  }
  
  .text-dark { // added
    --bs-dark-rgb: 103, 19, 124 !important;
  }

  .btn-primary {
    --bs-btn-bg: var(--#{$prefix}primary);
    --bs-btn-border-color: var(--#{$prefix}primary);
    --bs-btn-hover-bg: var(--#{$prefix}link-hover-color);
    --bs-btn-hover-border-color: var(--#{$prefix}link-hover-color);
    --bs-btn-active-bg: var(--#{$prefix}link-hover-color);
    --bs-btn-active-border-color: var(--#{$prefix}link-hover-color);
    --bs-btn-disabled-bg: var(--#{$prefix}primary);
    --bs-btn-disabled-border-color: var(--#{$prefix}primary);
  }

  .btn-link {
    //      --bs-btn-color: var(--#{$prefix}primary);
    --bs-btn-color: var(--#{$prefix}link-color);
    //      --bs-link-color: var(--#{$prefix}primary);
    --bs-btn-hover-color: var(--#{$prefix}link-hover-color);
    --bs-btn-active-color: var(--#{$prefix}link-hover-color);
  }

  .btn-outline-primary {
    --bs-btn-color: var(--#{$prefix}primary);
    --bs-btn-border-color: var(--#{$prefix}primary);
    --bs-btn-hover-bg: var(--#{$prefix}primary);
    --bs-btn-hover-border-color: var(--#{$prefix}primary);
    --bs-btn-active-bg: var(--#{$prefix}primary);
    --bs-btn-active-border-color: var(--#{$prefix}primary);
    --bs-btn-disabled-color: var(--#{$prefix}primary);
    --bs-btn-disabled-border-color: var(--#{$prefix}primary);
  }

  .btn-primary-soft {
    color: var(--#{$prefix}primary);
    background-color: rgba(var(--#{$prefix}primary-rgb), 0.1);

    &:hover,
    &:focus {
      color: $white;
      background-color: var(--#{$prefix}primary) !important;
      border-color: var(--#{$prefix}primary) !important;
    }
  }

  .fill-primary {
    fill: var(--#{$prefix}primary) !important;
  }

  .nav-pills {
    --bs-nav-pills-link-active-bg: var(--#{$prefix}primary);
  }

  .form-control:focus {
    border-color: var(--#{$prefix}primary);
  }

  .form-check-input:checked {
    background-color: var(--#{$prefix}primary);
    border-color: var(--#{$prefix}primary);
  }

  .form-select:focus {
    border-color: var(--#{$prefix}primary);
  }

  .form-check-input:focus {
    border-color: var(--#{$prefix}primary);
  }

  .pagination {
    --bs-pagination-hover-bg: var(--#{$prefix}primary);
    --bs-pagination-hover-border-color: var(--#{$prefix}primary);
    --bs-pagination-active-bg: var(--#{$prefix}primary);
    --bs-pagination-active-border-color: var(--#{$prefix}primary);
  }
}

/*User CSS*/

// Moments Table Styles
#momentsTable {
  tbody tr:hover {
    cursor: pointer;
  }

  .time {
    font-size: 0.7rem;
  }
}

// Reel Icon Styles (used in and out of Moments Table)
.reel-icon {
  font-size: 2em;
}

.reel-icon:hover {
  cursor: pointer;
}

.bi-eye-fill {
  color: green;
}

.bi-eye-slash {
  color: red;
}

// SearchPanes styling
.dtsp-searchPanes {
  justify-content: unset !important;
}

.dtsp-collapseAll {
  display: none;
}

.dtsp-showAll {
  display: none;
}

// Styling for the note and tags cells in the datatable
.clamped-cell {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-size: 0.9rem;  /* Adjust as needed */
  line-height: 1.2;  /* Adjust as needed */
  min-height: calc(0.9rem * 1.2 * 3);  /* Minimum height for three lines of text */
  word-wrap: break-word; 
  white-space: normal;
}

.moment-row:hover .clamped-cell {
  -webkit-line-clamp: 1000 !important; // None did not work, so I used a large number
  line-clamp: 1000 !important;
  overflow: visible !important;
  text-overflow: clip !important;
  max-height: none !important;
}

// Styling for split cells in the datatable (used for moments with intros)
.split-cell {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;  /* Adjust as needed */
  line-height: 1.2;  /* Adjust as needed */
  min-height: calc(0.9rem * 1.2 * 3);  /* Minimum height for three lines of text */
  overflow: hidden;
}

.split-cell .top-line,
.split-cell .bottom-lines {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
}

.split-cell .top-line {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: calc(0.9rem * 1.2);  /* Minimum height for one line of text */
}

.split-cell .bottom-lines {
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: calc(0.9rem * 1.2 * 2);  /* Minimum height for two lines of text */
}

.moment-row:hover .split-cell .top-line,
.moment-row:hover .split-cell .bottom-lines {
  -webkit-line-clamp: 1000;  /* Expand to show all content */
  line-clamp: 1000;
  overflow: visible;
  text-overflow: clip;
  max-height: none;
}

.agenda-item-row {
  border-top: 2px solid var(--bs-primary) !important;
  border-bottom: 2px solid var(--bs-primary) !important;
}

.agenda-item-row,
.agenda-item-row td {
  background-color: var(--bs-primary-bg-subtle) !important;
  pointer-events: none;
  cursor: default;
}

/* Override selected row color */
table.table.dataTable > tbody > tr.selected > *,
table.table.dataTable > tbody > tr.odd.selected > * {
    box-shadow: inset 0 0 0 9999px rgba(var(--bs-primary-rgb), 0.6) !important;
}

/* Make selected row color more prominent on hover */
table.table.dataTable > tbody > tr.selected:hover > * {
    box-shadow: inset 0 0 0 9999px rgba(var(--bs-primary-rgb), 0.7) !important;
}

// Sticky top.
.sticky-top-seegov {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

/* Disable sticky-top on short screens (generally mobile in landscape) */
@media (max-height: 500px) {
  .sticky-top-seegov {
    position: static;
  }
}

// Stick to the top on medium screens and up. Start 55px down to leave room for header. 
.sticky-top-md {
  position: -webkit-sticky;
  position: sticky;
  top: 55px;
  z-index: 2;
}

// Disable sticky-top-md on mobile screens
@media (max-width: 767.98px) {
  .sticky-top-md {
    position: relative;
    top: 0px;
  }
}

.clip-card {
  flex: 0 1 30%; /* Limit width to 30% of parent container */

  transition: box-shadow 0.3s ease; /* Smooth transition for box-shadow */
  
  &:hover {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); /* Subtle glow effect */
  }
}

.clip-card .card-body {
  cursor: pointer; /* Show pointer on hover */
}

/* Highlight clips in transcript */
.clip-highlight {
  background-color: rgba(13, 110, 253, 0.1); /* Bootstrap primary color at 10% */
  border-bottom: 2px solid rgba(13, 110, 253, 0.5);
}

// Processing overlay
.processing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Ensure it is above other elements */
  display: none; /* Initially hidden */
}

// Extra small button
.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

// Used to highlight changed values
@keyframes highlightFade {
  0% {
    background-color: yellow;
  }
  100% {
    background-color: transparent;
  }
}

.highlight {
  animation: highlightFade 1s ease;
}

/* Increase the size and brightness of the carousel control icons */
/* There is some additional styling of these in the dark mode styles above */
.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3rem; /* Increase icon size */
    height: 3rem; /* Increase icon size */
  }

/* Adjust the control buttons to only be the size of the icons */
.carousel-control-prev,
.carousel-control-next {
    width: 3rem; /* Remove default width */
    height: 3rem; /* Remove default height */
    padding: 0; /* Remove padding */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute; /* Position absolutely */
    top: 110px; /* Position 100 pixels down from the top */
    opacity: .8; /* Make it fully opaque */
    background-color: var(--bs-primary); /* Use the primary color */
  }

/* Ensure the buttons are positioned correctly */
.carousel-control-prev {
  left: 1rem; /* Adjust as needed */
}

.carousel-control-next {
  right: 1rem; /* Adjust as needed */
}

/* Mobile touch device button focus override */
/* Without this, the load more button was staying selected after being clicked */
@media (hover: none) and (pointer: coarse) {
  .btn-primary:focus {
    outline: none;
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;
    box-shadow: none !important;
  }
}

.dropdown-header {
  padding-left: var(--bs-dropdown-item-padding-x);
}